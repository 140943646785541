import { DataProvider } from "./context/DataProvider";
import CustomBarChart from "./Graphs/BarChart";
import CustomBarChartResponsive from "./Graphs/BarChartResponsive";
import CustomLineChartResponsive from "./Graphs/LineChartResponsive";
import CustomLineChart from "./Graphs/LineChart";
import valueFormatter from "./utils/valueFormatter";

const App = () => {
  return (
    <>
      <DataProvider apiConfig={{ range: "Översikt!A21:M31" }}>
        <CustomBarChartResponsive max={100} unit="%" />
      </DataProvider>
      <DataProvider
        exclude={["Totalt"]}
        times={1000}
        apiConfig={{ range: "Översikt!A61:M72" }}
      >
        <CustomBarChartResponsive unit="kr" />
      </DataProvider>
      <DataProvider
        exclude={["Totalt"]}
        times={1000}
        apiConfig={{ range: "Översikt!A99:M109" }}
      >
        <CustomBarChartResponsive unit="kr" />
      </DataProvider>
      <DataProvider times={1000} apiConfig={{ range: "Översikt!A135:M138" }}>
        <CustomLineChartResponsive hideLegend unit="kr" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Matchdag!A18:M21" }}>
        <CustomLineChartResponsive hideLegend unit="kr" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Matchdag!A47:M50" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Marknad!A18:M21" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Marknad!A47:M50" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider
        times={1000}
        apiConfig={{ range: "Spelarförsäljning!A18:M21" }}
      >
        <CustomLineChartResponsive hideLegend unit="kr" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "Spelarförsäljning!A47:M50" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>
      <DataProvider apiConfig={{ range: "SEF och dylikt!A18:N21" }}>
        <CustomLineChartResponsive
          hideLegend
          unit="%"
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Andel",
              min: 0,
              max: 10
            },
          ]}
        />
      </DataProvider>

      <DataProvider apiConfig={{ range: "SEF och dylikt!A44:N47" }}>
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 16,
              reverse: true,
            },
          ]}
        />
      </DataProvider>

      <DataProvider apiConfig={{ range: "SEF och dylikt!A67:M70" }}>
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A18:M21" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 18,
              reverse: true,
            },
          ]}
        />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A47:M50" }}
      >
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A75:M78" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Tabellplacering",
              min: 1,
              max: 20,
              reverse: true,
            },
          ]}
        />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Fotboll (inkl. internationella cuper)!A107:M110" }}
      >
        <CustomLineChartResponsive hideLegend unit="%" />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A18:J22" }}>
        <CustomLineChartResponsive />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A47:J50" }}>
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A69:M72" }}>
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A99:M102" }}>
        <CustomLineChartResponsive hideLegend min={100} />
      </DataProvider>

      <DataProvider apiConfig={{ range: "Medlemmar och supportrar!A128:M131" }}>
        <CustomLineChartResponsive hideLegend />
      </DataProvider>

      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A22:N25" }}
      >
        <CustomBarChartResponsive hideLegend />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A51:M54" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Antal",
              min: 1,
              max: 10,
            },
          ]}
        />
      </DataProvider>
      <DataProvider
        apiConfig={{ range: "Styrelse, valberedning och organisation!A86:M89" }}
      >
        <CustomLineChartResponsive
          hideLegend
          yAxis={[
            {
              id: "primary",
              position: "left",
              label: "Antal",
              min: 0,
              max: 80,
            },
          ]}
        />
      </DataProvider>
      {/* <DataProvider
        localFile="Ekonomi.A49:M58.json"
        apiConfig={{
          range: "Ekonomi - sammanställning!A49:M58",
        }}
      >
        <Incomes />
      </DataProvider>
      <Costs />
      <Members />
      <Result />
      <Audience />
      <Market />
      <Organization />
      <IncomeTrend />
      <MembersTrend />
      <PositionU19 />
      <Position />
      <Search />
      <Instagram /> */}
    </>
  );
};

export default App;
